import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  sessionId: any;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.sessionId = params.get('sessionId');
      this.initiatePayment();
    });
  }

  initiatePayment() {
    loadStripe(environment.stripePublishableKey).then((stripe) => {
      stripe?.redirectToCheckout({
        sessionId: this.sessionId,
      });
    });
  }
}
